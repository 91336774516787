import axios from 'axios'
import { Message } from 'element-ui';
// axios.defaults.baseURL = 'http://pc.danling.cdwsx.cn/'// 线上
axios.defaults.baseURL = 'https://danlingzp.com/'// 线上
// axios.defaults.baseURL = 'http://192.168.1.126:19001/'// 超哥
axios.defaults.timeout = 60000 // 设置请求超时
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;'
// 参数拦截器配置
axios.interceptors.request.use(
    async config => {
        // 添加前端版本号请求头
        const userInfo = window.sessionStorage.getItem('userInfo')
        config.headers['customerType'] =   1
        if (userInfo) {
            config.headers['X-Access-Token'] = JSON.parse(userInfo).token || ''
            config.headers['customerType'] = JSON.parse(userInfo).loginDO.loginType
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    res => {
        if(res.request.responseType === 'arraybuffer'){
            return res
        }
        if (Number(res.data.code) !== 200) {
            Message({
                message: res.data.msg,
                type: 'error'
            })
            if (Number(res.data.code) === 500 && Number(res.data.msg) === 403) {
                Message.closeAll()
                Message({
                    message: '登录过期，请重新登录',
                    type: 'error'
                })
                setTimeout(() => {
                    location.href='/login'
                }, 1000);
                return Promise.reject(res.data)
            }
            return Promise.reject(res.data)
            // 签名失效 重新登录
        } else {
            return res.data
        }
    },
    error => {
        // debugger
        if (Number(error.response.data.message) == 403) {
            Message.closeAll()
            Message({
                message: '登录过期，请重新登录',
                type: 'error'
            })
            setTimeout(() => {
                location.href='/login'
            }, 1000);
            return Promise.reject(error)
        } else {
            Message.closeAll()
            Message({
                message: error.toString(),
                type: 'error'
            })
        }
        return Promise.reject(error)
    }
)
export default axios
